import React from "react";
import { graphql, Link } from "gatsby";
import { gql } from "@apollo/client";
import { Helmet } from "react-helmet";

import { unwrap } from "../components/WagtailWrapper";
import { Wagtail, SectionFragment } from "../components/Wagtail";
import { Smallcaps } from "../components/Prose";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import {
  Section,
  WideContent,
  TightContent,
  Grid,
  Content,
} from "../components/Layouts";
import { MinimalHeader } from "../components/MinimalHeader";
import {
  ExperimentCard,
  ExperimentCardFragment,
  HistologyCard,
  HistologyCardFragment,
  InformaticsToolCard,
  InformaticsToolCardFragment,
  InstitutionCardFragment
} from "../components/Card";
import { Image } from "../components/Image";

import Participants from "../images/Icon_Participants.svg";
import InstitutionsIcon from "../images/Icon_Institution.svg";
import Samples from "../images/Icon_Samples.svg";
import Data from "../images/Icon_Data_Size.svg";
import ScientificProjects from "../images/Icon_Project.svg";

import { SEOTags } from "../components/SEOTags";
import { Button } from "../components/Button";

const RowOfStats = ({ items }) => (
  <div className="flex-4 text-center space-y-4 max-w-3xl">
    <div className="flex flex-wrap justify-around md:space-x-12">
      {items.map((item) => (
        <Link
          to={item.link}
          key={item.label}
          className="flex flex-col space-y-2 w-24 mt-8"
        >
          <Image className="max-h-10" src={item.icon} alt={item.label} />
          <span className="font-jaf text-navy-900 text-2xl leading-8 font-semibold sm:text-3xl sm:leading-9 md:text-3xl md:leading-12">
            {item.value}
          </span>
          <p className="text-lg">{item.label}</p>
        </Link>
      ))}
    </div>
  </div>
);

export const Component = ({ page, snippets, images, institutions }) => (
  <>
    <Helmet>
      <script type="application/ld+json">
        {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Children's Brain Tumor Network",
            "url": "https://cbtn.org",
            "logo": "https://cbtn-s3.imgix.net/CBTN_Logo-75d2fa12005a36d7609fc96afb47c2b7.png?w=180&h=80",
            "email": "contact@cbtn.org",
            "sameAs" : [
              "${page?.facebookUrl}",
              "${page?.twitterUrl}",
              "${page?.instagramUrl}",
              "${page?.youtubeUrl}"
            ],
            "contactPoint" : [
                {
                    "@type" : "ContactPoint",
                    "contactType" : "customer service",
                    "email": "${page?.supportEmail}",
                    "url": "https://cbtn.org"
                }
            ]
        }`}
      </script>
    </Helmet>
    <SEOTags
      page={page}
      url={``}
      title={page?.title}
      titleTemplate="%s"
      description={
        "The Children's Brain Tumor Network drives innovative discoveries, pioneers new treatments and accelerates open science to improve the health of every child and young adult diagnosed with a brain tumor"
      }
    />
    <Header />
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto ">
        <div className="relative z-10 pb-8 bg-gradient-to-r from-white via-white to-transparent sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full">
          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between sm:h-10 lg:justify-start"></div>
          </div>

          <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <Image
              className="h-20 mb-12"
              src="https://cbtn-s3.imgix.net/CBTN_Logo-75d2fa12005a36d7609fc96afb47c2b7.png"
              alt="CBTN_Logo-75d2fa12005a36d7609fc96afb47c2b7.png"
            />
            {page?.subheader && <Smallcaps>{page?.subheader}</Smallcaps>}
            {page?.header && (
              <h1 className="mt-2 mb-6 text-3xl leading-9 font-semibold tracking-tight sm:text-4xl sm:leading-10">
                {page?.header}
              </h1>
            )}
            <Wagtail
              sections={page?.headerText}
              snippets={snippets}
              images={images}
            />
          </div>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-3/4">
        <Image
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={page?.headerImage?.src}
          alt={page?.headerImage?.title}
          height={450}
        />
      </div>
    </div>
    <Section>
      <TightContent>
        <div className="text-center">
          <h2 className="mt-2 text-4xl leading-9 tracking-tight sm:text-5xl sm:leading-10">
            CBTN by the Numbers
          </h2>
          <Wagtail
            sections={page?.statisticsContent}
            snippets={snippets}
            images={images}
          />
        </div>
      </TightContent>
      <div className="flex justify-center">
        <RowOfStats
          items={[
            {
              icon: Participants,
              label: "Participants",
              value: page?.participants,
              link: "/research-resources",
            },
            {
              icon: InstitutionsIcon,
              label: "Institutions",
              value: institutions?.members?.filter((p) => p?.lastPublishedAt)
                ?.length,
              link: "/institutions",
            },
            {
              icon: Samples,
              label: "Samples",
              value: page?.samples,
              link: "/histologies",
            },
            {
              icon: Data,
              label: "Data Size",
              value: page?.dataSize,
              link: "/informatics-portals",
            },
            {
              icon: ScientificProjects,
              label: "Projects",
              value: page?.scientificProjects,
              link: "/projects",
            },
          ]}
        />
      </div>

      {page?.featuredProjects?.length > 0 && (
        <Section theme="dark">
          <WideContent>
            <MinimalHeader
              subTitle="featured"
              title="Scientific Projects"
              Right={
                <Button size={2} as={Link} to="/projects">
                  View All
                </Button>
              }
            />
            <Grid>
              {page?.featuredProjects?.slice(0, 3).map((m) => (
                <ExperimentCard
                  key={m.featuredProject?.slug}
                  item={m.featuredProject}
                />
              ))}
            </Grid>
          </WideContent>
        </Section>
      )}
    </Section>
    <Section>
      {page?.informaticsTools?.length > 0 && (
        <WideContent>
          <MinimalHeader
            subTitle="cbtn"
            title="Data Science"
            Right={
              <Button size={2} as={Link} to="/informatics-portals">
                View All
              </Button>
            }
          />
          <Grid>
            {page?.informaticsTools?.slice(0, 3).map((m) => (
              <InformaticsToolCard key={m.informaticsTool?.name} item={m} />
            ))}
          </Grid>
        </WideContent>
      )}

      {page?.featuredHistologies?.length > 0 && (
        <WideContent>
          <MinimalHeader
            subTitle="featured"
            title="Available Resources"
            Right={
              <Button size={2} as={Link} to="/histologies">
                View All
              </Button>
            }
          />
          <Grid>
            {page?.featuredHistologies?.slice(0, 3).map((m) => (
              <HistologyCard
                key={m.featuredHistology?.slug}
                item={m.featuredHistology}
              />
            ))}
          </Grid>
        </WideContent>
      )}
    </Section>

    <Footer />
  </>
);

export const previewQuery = gql`
  query previewHomePage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    institutions: page(slug: "member-institutions") {
      ... on MemberInstitutionListingPage {
        members {
          lastPublishedAt
          ...InstitutionCardFragment
        }
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on HomePage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          src
        }
        intro {
          ...SectionFragment
        }
        informaticsToolsContent {
          ...SectionFragment
        }
        informaticsTools(order: "sort_order") {
          ...InformaticsToolCardFragment
        }
        statisticsContent {
          ...SectionFragment
        }
        participants
        samples
        families
        dataSize
        scientificProjects
        featuredHistologies {
          featuredHistology {
            ...HistologyCardFragment
          }
        }
        featuredProjects {
          featuredProject {
            ...ExperimentCardFragment
          }
        }
        facebookUrl
        twitterUrl
        instagramUrl
        youtubeUrl
        supportEmail
      }
    }
  }
  ${InstitutionCardFragment}
  ${InformaticsToolCardFragment}
  ${HistologyCardFragment}
  ${ExperimentCardFragment}
  ${SectionFragment}
`;

export const query = graphql`
  query HomePage($id: Int) {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      institutions: page(slug: "member-institutions") {
        ... on Wagtail_MemberInstitutionListingPage {
          members {
            lastPublishedAt
            ...Wagtail_InstitutionCardFragment
          }
        }
      }
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_HomePage {
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            src
          }
          intro {
            ...Wagtail_SectionFragment
          }
          featuredProjects {
            featuredProject {
              ...Wagtail_ExperimentCardFragment
            }
          }
          informaticsToolsContent {
            ...Wagtail_SectionFragment
          }
          informaticsTools(order: "sort_order") {
            ...Wagtail_InformaticsToolCardFragment
          }
          statisticsContent {
            ...Wagtail_SectionFragment
          }
          participants
          samples
          families
          dataSize
          scientificProjects
          featuredHistologies {
            featuredHistology {
              ...Wagtail_HistologyCardFragment
            }
          }
          facebookUrl
          twitterUrl
          instagramUrl
          youtubeUrl
          supportEmail
        }
      }
    }
  }
`;

export default unwrap(Component);
